// New Functionality
$(document).ready(function () {
    $('#register-tab').on('click', function () {
        var registerTitle = $('.register-title');
        var loginTitle = $('.login-title');
        loginTitle[0].classList.add('hide');
        registerTitle[0].classList.remove('hide');
    });

    $('#login-tab').on('click', function () {
        var registerTitle = $('.register-title');
        var loginTitle = $('.login-title');
        registerTitle[0].classList.add('hide');
        loginTitle[0].classList.remove('hide');
    });

    $("select:not([aria-required*='true'])")
        .each(function () {
            if ($(this).children('option:selected').val() === '') { $(this).css('color', '#999'); }
        })
        .change(function () {
            if ($(this).children('option:selected').val() === '') { $(this).css('color', '#999'); } else { $(this).css('color', '#121212'); }
        });
    var inputs = $('input[required], select[required]');
    inputs.attr('oninvalid', "this.setCustomValidity('Required!')");
    inputs.attr('oninput', "setCustomValidity('')");
});

var registrationFormEmailConfirm = document.getElementById('registration-form-email-confirm');
var registrationFormPasswordConfirm = document.getElementById('registration-form-password-confirm');
registrationFormEmailConfirm.onpaste = e => {
    e.preventDefault();
    return false;
};
registrationFormPasswordConfirm.onpaste = e => {
    e.preventDefault();
    return false;
};
